import React, { useState, useEffect } from "react";
import Layout from "../Layout/Content";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState([]);
  const [price, setPrice] = useState("");
  const [coinId, setCoinId] = useState("");
  const [increment, setIncrement] = useState("");
  const handlePriceDetails = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/admin/admin-coins`);
      if (resp.status === 200) {
        setPriceDetails(resp?.data?.data);
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleSetPrice = async () => {
    setLoading(true);
    try {
      const data = {
        coinId: coinId,
        newAdminCoinPrice: price,
        newIncrement: increment,
      };
      const resp = await axios.put(`/api/admin/editAdmin-coinsRate`, data);
      if (resp.status === 200) {
        toast.success(resp?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handlePriceDetails();
  }, []);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-withdraw">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h2 className="db-heading">Price Form</h2>
                  <hr className="my-4" />
                  <div className="table-responsive">
                    <table id="myTable" className="display table">
                      <thead>
                        <tr>
                          <th>Coin Name</th>
                          <th>Price</th>
                          <th>Increment</th>
                          <th>Edit Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceDetails &&
                          priceDetails.map((value, key) => {
                            console.log("value", value);
                            return (
                              <tr>
                                <td>{value.coinName}</td>
                                <td>{value.coinRate}</td>
                                <td>{value.increase}</td>

                                <td>
                                  <button
                                    type="button"
                                    className="cus-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal2"
                                    onClick={() => (
                                      setCoinId(value?.coinId),
                                      setIncrement(value?.increase),
                                      setPrice(value?.coinRate)
                                    )}
                                  >
                                    <i class="far fa-eye"></i> View
                                  </button>

                                  {/* modal */}
                                  <div
                                    className="modal fade"
                                    id="exampleModal2"
                                    tabIndex={-1}
                                    data-bs-target="#exampleModal2"
                                    aria-labelledby=""
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabel2"
                                          >
                                            Price Form
                                          </h5>
                                          <button
                                            type="button"
                                            className="close-modal"
                                            data-bs-dismiss="modal"
                                            aria-label=""
                                          >
                                            <i class="far fa-times"></i>
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                              <div className="card">
                                                <div className="row mb-3 align-items-center">
                                                  <div className="col-4">
                                                    <label
                                                      htmlFor="name"
                                                      className="text-light"
                                                    >
                                                      Price
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <input
                                                      type="float"
                                                      className="form-control"
                                                      placeholder="Enter Coin Price"
                                                      value={price}
                                                      onChange={(e) =>
                                                        setPrice(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="row mb-3 align-items-center">
                                                  <div className="col-4">
                                                    <label
                                                      htmlFor="email"
                                                      className="text-light"
                                                    >
                                                      Increment
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter W"
                                                      value={increment}
                                                      onChange={(e) =>
                                                        setIncrement(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            className="cus-btn"
                                            onClick={() => handleSetPrice()}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>

    // <Layout>
    //   <div className="db-content">
    //     <div className="container-fluid">
    //       <div className="db-section db-price">
    //         <div className="row">
    //           <div className="col-lg-8 col-md-12 col-sm-12">
    //             <div className="card">
    //               <h3>Price Form</h3>
    //               <hr className="my-4" />
    //               <form action>
    //                 <div className="row mb-3 align-items-center">
    //                   <div className="col-4">
    //                     <label htmlFor="name">Price</label>
    //                   </div>
    //                   <div className="col-8">
    //                     <input
    //                       type="number"
    //                       className="form-control"
    //                       placeholder="Enter Coin Price"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="row mb-3 align-items-center">
    //                   <div className="col-4">
    //                     <label htmlFor="email">Time</label>
    //                   </div>
    //                   <div className="col-8">
    //                     <input
    //                       type="datetime-local"
    //                       className="form-control"
    //                       placeholder="Time"
    //                     />
    //                   </div>
    //                 </div>

    //                 <a href="#" className="button mt-2">
    //                   Submit
    //                 </a>
    //               </form>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  );
};

export default Content;
