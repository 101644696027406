import React from "react";
import { InfinitySpin, Triangle } from "react-loader-spinner";
import { useState, useEffect } from "react";
import "./styles.css";

const Loading = (props) => {
  const [loadingVisiblity, setLoadingVisiblity] = useState("none");
  useEffect(() => {
    if (props.loading) {
      setLoadingVisiblity("flex");
    } else {
      setLoadingVisiblity("none");
    }
  }, [props.loading]);
  return (
    <div className="loading" style={{ display: loadingVisiblity }}>
      <div>
        <Triangle
          height="80"
          width="80"
          color="#0eb7d1"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        {/* <InfinitySpin width="200" height="100" color="#ab4abb" /> */}
        <h5 style={{ color: "#0dcaf0", fontWeight: "bold", marginTop: "31px" }}>
          Loading....
        </h5>
      </div>
    </div>
  );
};

export default Loading;
