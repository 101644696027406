import React, { useState } from "react";
// import "./styles.css";
import Loading from "../LoaderPageSection/Loading";
import Layout from "../Layout/Content";
const Content = () => {
  const [loading, setLoading] = useState("");
  const [price, setPrice] = useState("");
  const [coinName, setCoinName] = useState("");
  const handleSubmit = () => {
    console.log(price, coinName);
    alert("in Progress");
  };
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card candel-card">
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    Select Coin
                  </label>
                </div>
                <div className="col-8">
                  <select
                    className="form-select"
                    value={coinName}
                    onChange={(e) => setCoinName(e.target.value)}
                  >
                    <option value="" disabled>
                      ----Select Coin Mode----
                    </option>
                    <option selected value="HyperXAlpha">
                      HyperXAlpha
                    </option>
                    <option value="HyperCoin">HyperCoin</option>
                  </select>
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    Change Price
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Change Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3 align-items-center">
                <div className="col-6">
                  <button className="cus-btn" onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
