import React from "react";
import DebitcreditPageSection from "../sections/DebitcreditPageSection/Content";
const DebitcreditPage = () => {
  return (
    <div>
      <DebitcreditPageSection />
    </div>
  );
};

export default DebitcreditPage;
