import React, { useState, useEffect } from "react";
import Layout from "../Layout/Content";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../LoaderPageSection/Loading";
import ReactPaginate from 'react-paginate';

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [depositClick, setDepositClick] = useState({
    id: "",
    depositeAmount: "",
    userId: "",
  });
  const [rejectData, setRejectData] = useState({
    id: "",
    userId: "",
  });
  const [rejectMessage, setRejectMessage] = useState("");
  const [reason, setReason] = useState("");

  const adminAddress = "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d";
  const [screenShort, setScreenShot] = useState("");
  const url = "https://backend.hyperxalpha.com";
  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = depositData?.getdepositDetails
  const pageCount = depositData?.pagination?.totalPages
  const handlePageClick = async(event) => {
    const newOffset = (event.selected * itemsPerPage) % depositData.length;
    setItemOffset(newOffset);
      const resp = await axios.get(`/api/admin/getdepositDetails?page=${event.selected+1}`);
      setDepositData(resp?.data);

  };
  const handleDepositDetails = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/admin/getdepositDetails`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setDepositData(resp?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleDepositAprove = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(`/api/admin/approveDeposite`, depositClick);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleDepositReject = async () => {
    setLoading(true);
    try {
      const depositRejectData = {
        id: rejectData?.id,
        userId: rejectData?.userId,
        ReasonOfRejection: reason,
        message: rejectMessage,
      };
      const resp = await axios.post(
        `/api/admin/rejectDeposite`,
        depositRejectData
      );
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    handleDepositDetails();
  }, []);
  console.log("depositClick", depositClick);

  return (
    <Layout>
      {/* {loading && <Loading loading={loading} />} */}
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-withdraw">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h2 className="db-heading">Deposit Form</h2>
                  <hr className="my-4" />
                  <div className="table-responsive">
                    <table id="myTable" className="display table">
                      <thead>
                        <tr>
                          <th>UserId</th>
                          <th>Email</th>
                          {/* <th>Address</th> */}
                          <th>Amount</th>
                          <th>Deposit</th>
                          <th>Reject</th>
                          <th>screenShort</th>
                          <th>Status</th>
                          <th>Date Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((value, key) => {
                            return (
                              <tr>
                                <td>{value?.userId}</td>
                                <td>{value?.email}</td>
                                {/* <td>{value?.accountAddress}</td> */}
                                <td>{value?.depositAmount}</td>
                                <td>
                                  {/* Button trigger modal */}
                                  <button
                                    type="button"
                                    className="cus-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() =>
                                      setDepositClick({
                                        id: value?._id,
                                        depositeAmount: value?.depositAmount,
                                        userId: parseInt(value.userId),
                                      })
                                    }
                                  >
                                    Deposit
                                  </button>
                                  {/* Modal */}
                                  <div
                                    className="modal fade"
                                    id="exampleModal"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content">
                                        <button
                                          type="button"
                                          className="close-modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <i className="far fa-times" />
                                        </button>
                                        <div className="modal-body">
                                          <h2 className="db-heading ">
                                            Deposit Information
                                          </h2>
                                          <hr className="my-4" />
                                          <div className="form">
                                            {/* <div className="row mb-3 align-items-center">
                                              <div className="col-4">
                                                <label htmlFor="name">
                                                  Admin
                                                </label>
                                              </div>
                                              <div className="col-8">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Admin Address"
                                                  value={adminAddress}
                                                />
                                              </div>
                                            </div> */}
                                            <div className="row mb-3 align-items-center">
                                              <div className="col-4">
                                                <label htmlFor="email">
                                                  User Id
                                                </label>
                                              </div>
                                              <div className="col-8">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="user Id"
                                                  value={depositClick.userId}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                              <div className="col-4">
                                                <label htmlFor="password">
                                                  Amount
                                                </label>
                                              </div>
                                              <div className="col-8">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Amount"
                                                  value={
                                                    depositClick.depositeAmount
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <button
                                              className="button mt-2 w-100"
                                              onClick={() =>
                                                handleDepositAprove()
                                              }
                                            >
                                              Deposit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {/* Button trigger modal */}
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalinactive"
                                    onClick={() => {
                                      setRejectData({
                                        id: value._id,
                                        userId: value?.userId,
                                      });
                                    }}
                                  >
                                    Reject
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="cus-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalV"
                                    onClick={() =>
                                      setScreenShot(value?.paymentScreenShot)
                                    }
                                  >
                                    <i class="far fa-eye"></i> View
                                  </button>

                                  <div
                                    className="modal fade"
                                    id="exampleModalV"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content">
                                        <button
                                          type="button"
                                          className="close-modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <i className="far fa-times" />
                                        </button>
                                        <div className="modal-body">
                                          <h2 className="db-heading">
                                            Depoist Image
                                          </h2>
                                          <hr className="my-4" />
                                          <div className="row">
                                            <div className="col-md-12 col-12">
                                              <div className="adhar-img">
                                                <img
                                                  src={`${url}/uploads/depositScreenShot/${screenShort}`}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{value?.Approval}</td>
                                <td>{value?.depositDate}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <ReactPaginate
        previousLabel={"<< "}
        nextLabel={" >>"}
        breakLabel={"..."}
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        // renderOnZeroPageCount={null}
      />
                    {/* Modal  */}
                    <div
                      className="modal fade"
                      id="exampleModalinactive"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabelinactive"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog p-0 radius-0">
                        <div className="modal-content rounded-0">
                          <div className="modal-header">
                            <h5
                              className="modal-title text-light"
                              id="exampleModalLabelinactive"
                            >
                              Reject Reason
                            </h5>
                            <button
                              type="button"
                              className="close-modal"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i class="far fa-times"></i>{" "}
                            </button>
                          </div>
                          <div className="modal-body p-0">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label text-light"
                              >
                                Reason
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Enter Reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                className="form-label text-light"
                              >
                                message
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                defaultValue={""}
                                value={rejectMessage}
                                onChange={(e) =>
                                  setRejectMessage(e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handleDepositReject()}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* modal-red */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
