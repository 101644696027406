import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import Swal from "sweetalert2";
import { useAuth } from "../context/auth";
const Content = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parseData = JSON.parse(data);
      setAuth({
        ...auth,
        user: parseData.data,
        token: parseData.token,
      });
    } else {
      navigate("/");
    }
  }, []);

  const handleLogOut = () => {
    Swal.fire({
      text: "Are you sure you won't be Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAuth({
          ...auth,
          user: null,
          token: "",
        });
        localStorage.removeItem("auth");
        navigate("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        alert("Cancelled");
      }
    });
  };
  return (
    <>
      <section className={`dashboard ${isShow && "db-active"}`}>
        <aside className="sidebar">
          <div className="logo">
            <Link to={"/dashboard"}>
              <span>H</span>XA
            </Link>
            <button className="close-button" onClick={() => setIsShow(!isShow)}>
              <i className="far fa-times" />
            </button>
          </div>
          <div className="db-menu">
            <ul>
              <li className="nav-item">
                <Link to={"/dashboard"} className="nav-link active">
                  <i className="far fa-tachometer-alt-slow me-2" />
                  <span className="nav-title">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/withdraw"} className="nav-link">
                  <i className="far fa-file-invoice-dollar me-2" />
                  <span className="nav-title">Withdraw</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/price"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title"> Price</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/kyc"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title"> KYC</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/deposit"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title"> Deposit</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/candleincrease"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title"> CandleIncrease</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/debitcredit"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title"> Debit / Credit</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/change"} className="nav-link">
                  <i className="far fa-sack-dollar me-2" />
                  <span className="nav-title">Change</span>
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="far fa-user-circle me-2" />
                    <span className="nav-title">Profile</span>
                  </span>
                  <i className="far fa-chevron-down dropdown-arrow" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="far fa-users me-2" />
                    <span className="nav-title">Team</span>
                  </span>
                  <i className="far fa-chevron-down dropdown-arrow" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" />
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="fab fa-stack-exchange me-2" />
                    <span className="nav-title">Stacking</span>
                  </span>
                  <i className="far fa-chevron-down dropdown-arrow" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Something else here
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="far fa-wallet me-2" />
                    <span className="nav-title">Wallet</span>
                  </span>
                  <i className="far fa-chevron-down dropdown-arrow" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="far fa-circle me-2" /> Something else here
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </aside>
        <div className="db-body">
          <div className="db-nav bg-dark-alt">
            <nav className="navbar navbar-expand">
              <div className="container-fluid">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100">
                    <li className="nav-item">
                      <button
                        className="nav-link toggle-btn"
                        onClick={() => setIsShow(!isShow)}
                      >
                        <i className="far fa-bars" />
                      </button>
                    </li>
                    <li className="nav-item dropdown ms-auto">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#8"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="far fa-user text-primary me-2" /> Accounts
                      </a>
                      <ul className="dropdown-menu shadow">
                        {!auth.user ? (
                          <li>
                            <Link to={"/"}>
                              <a className="dropdown-item">
                                <i className="far fa-sign-in me-2" /> Login
                              </a>
                            </Link>
                          </li>
                        ) : (
                          <li>
                            <span className="dropdown-item text-light">
                              <i class=" far fa-solid fa-user me-2"></i>
                              {auth?.user?.name}
                            </span>
                            <button
                              className="dropdown-item"
                              onClick={() => handleLogOut()}
                            >
                              <i className="far fa-sign-in me-2" /> Log Out
                            </button>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          {children}
        </div>
      </section>
    </>
  );
};

export default Content;
