import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashbordPage from "./pages/DashbordPage";
import WithdrawPage from "./pages/WithdrawPage";
import KycPage from "./pages/KycPage";
import PricePage from "./pages/PricePage";
import DepositPage from "./pages/DepositPage";
import CandleIncreasePage from "./pages/CandleIncreasePage";
import DebitcreditPage from "./pages/DebitcreditPage";
import ForgotPage from "./pages/ForgotPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ChangePage from "./pages/ChangePage";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashbordPage />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/kyc" element={<KycPage />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/candleincrease" element={<CandleIncreasePage />} />
        <Route path="/forgot-email" element={<ForgotPage />} />
        <Route path="/forgot-password/:id" element={<ForgotPasswordPage />} />
        <Route path="/debitcredit" element={<DebitcreditPage />} />
        <Route path="/change" element={<ChangePage />} />
      </Routes>
    </>
  );
}

export default App;
