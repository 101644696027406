import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../LoaderPageSection/Loading";
import ReactPaginate from 'react-paginate';

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [debit, setDebit] = useState([]);
  const [useId, setUserId] = useState("");
  const [amount, setAmount] = useState("");
  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = debit?.allRegisterUser
  const pageCount = debit?.pagination?.totalPages
  const handlePageClick = async(event) => {
    const newOffset = (event.selected * itemsPerPage) % debit.pagination.totalItems;
      const resp = await axios.get(`/api/admin/admin-AllUser?page=${event.selected+1}`);
        setDebit(resp?.data);
        setItemOffset(newOffset);
  };
  const handleDebitCredit = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/admin/admin-AllUser`);
      if (resp.status === 200) {
        setDebit(resp?.data);
        console.log(resp?.data,'resp.data')
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const handleDebit = async () => {
    const body = {
      CreaditAmount: Number(amount),
      userId: useId,
    };
    setLoading(true);
    try {
      const resp = await axios.post(`/api/admin/AdminCreditAmount`, body);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleDebitCredit();
  }, []);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-withdraw">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h2 className="db-heading">Debit/Credit Form</h2>
                  <hr className="my-4" />
                  <div className="table-responsive">
                    <table id="myTable" className="display table">
                      <thead>
                        <tr>
                          <th>User Id</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>isActive</th>
                          <th>RefferalCode</th>
                          <th>Credit</th>
                          {/* <th>Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((value, key) => {
                            return (
                              <tr>
                                <td>{value.userId}</td>
                                <td>{value.firstName}</td>
                                <td>{value.lastName}</td>
                                <td>{value.email}</td>
                                <td>
                                  {value.isActive ? "Active" : "InActive"}
                                </td>
                                <td>{value.refferalCode}</td>
                                <td>
                                  {" "}
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalinactive"
                                    onClick={() => setUserId(value.userId)}
                                  >
                                    Credit
                                  </button>
                                  {/* Modal  */}
                                  <div
                                    className="modal fade"
                                    id="exampleModalinactive"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabelinactive"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabelinactive"
                                          >
                                            Credit Amount
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          />
                                        </div>
                                        <div className="modal-body">
                                          <div className="mb-3">
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className="form-label"
                                            >
                                              User Id
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              placeholder="Enter Reason"
                                              value={useId}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label
                                              htmlFor="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              Amount
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              placeholder="Enter Amount"
                                              value={amount}
                                              onChange={(e) =>
                                                setAmount(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleDebit()}
                                          >
                                            Save changes
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* modal-red */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <ReactPaginate
        previousLabel={"<< "}
        nextLabel={" >>"}
        breakLabel={"..."}
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        // renderOnZeroPageCount={null}
      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
