import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../LoaderPageSection/Loading";
import ReactPaginate from "react-paginate";

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [kycDetails, setKycDetails] = useState([]);
  const [reason, setReason] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");
  const [adharFront, setAdharFront] = useState("");
  const [adharBack, setAdharBack] = useState("");
  const [rejectId, setRejectId] = useState("");
  // const url = "https://devbackend.hyperxalpha.com";
  const url = "https://backend.hyperxalpha.com";
  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = kycDetails?.data;
  const pageCount = kycDetails?.pagination?.totalPages;
  const handlePageClick = async (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % kycDetails.pagination.totalItems;
    console.log(event.selected, "newOffset");
    setItemOffset(newOffset);
    const resp = await axios.get(
      `/api/admin/getAll-kycRequests?page=${event.selected + 1}`
    );
    setKycDetails(resp?.data);
  };
  const handleKycDetails = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/admin/getAll-kycRequests`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setKycDetails(resp?.data);
          console.log(resp);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const handleimageName = (adharFront, adharBack) => {
    setAdharFront(adharFront);
    setAdharBack(adharBack);
  };
  const handleKycApprove = async (userId) => {
    const body = {
      userId,
    };
    setLoading(true);
    try {
      const resp = await axios.post(`/api/admin/approve-kycRequests`, body);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleRejectkyc = async () => {
    setLoading(true);
    try {
      const data = {
        userId: rejectId,
        message: rejectMessage,
        ReasonOfRejection: reason,
      };
      const resp = await axios.post(`/api/admin/reject-kycRequests`, data);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    handleKycDetails();
  }, []);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-withdraw">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h2 className="db-heading">KYC Form</h2>
                  <hr className="my-4" />
                  <div className="table-responsive">
                    <table id="myTable" className="display table">
                      <thead>
                        <tr>
                          <th>User Id</th>
                          <th>Type</th>
                          <th>Email</th>
                          <th>aadhar/Licence</th>
                          <th>Images</th>
                          <th>Button</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((value, key) => {
                            return (
                              <tr>
                                <td>{value.userId}</td>
                                <td>{value.kycType}</td>
                                <td>{value.email}</td>
                                <td>{value.aadharNumber || value.licenseNumber}</td>

                                <td>
                                  <button
                                    type="button"
                                    className="cus-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() =>
                                      handleimageName(
                                        value?.aadharFront
                                          ? value?.aadharFront
                                          : value.drivingFront,
                                        value?.aadharBack
                                          ? value?.aadharBack
                                          : value.drivingBack
                                      )
                                    }
                                  >
                                    <i class="far fa-eye"></i> View
                                  </button>

                                  <div
                                    className="modal fade"
                                    id="exampleModal"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                      <div className="modal-content">
                                        <button
                                          type="button"
                                          className="close-modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <i className="far fa-times" />
                                        </button>
                                        <div className="modal-body">
                                          <h2 className="db-heading">
                                            Adhar Card
                                          </h2>
                                          <hr className="my-4" />
                                          <div className="row">
                                            <div className="col-md-6 col-12">
                                              <div className="adhar-img">
                                                <img
                                                  src={`${url}/uploads/${adharFront}`}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                              <div className="adhar-img">
                                                <img
                                                  src={`${url}/uploads/${adharBack}`}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn btn-success mx-1"
                                    onClick={() =>
                                      handleKycApprove(value.userId)
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalinactive"
                                    onClick={() => setRejectId(value.userId)}
                                  >
                                    Reject
                                  </button>

                                  {/* Modal  */}
                                  <div
                                    className="modal fade"
                                    id="exampleModalinactive"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabelinactive"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabelinactive"
                                          >
                                            Reject Reason
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          />
                                        </div>
                                        <div className="modal-body">
                                          <div className="mb-3">
                                            <label
                                              htmlFor="exampleFormControlInput1"
                                              className="form-label"
                                            >
                                              Reason
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="exampleFormControlInput1"
                                              placeholder="Enter Reason"
                                              value={reason}
                                              onChange={(e) =>
                                                setReason(e.target.value)
                                              }
                                              required
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label
                                              htmlFor="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              message
                                            </label>
                                            <textarea
                                              className="form-control"
                                              id="exampleFormControlTextarea1"
                                              rows={3}
                                              defaultValue={""}
                                              value={rejectMessage}
                                              onChange={(e) =>
                                                setRejectMessage(e.target.value)
                                              }
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleRejectkyc()}
                                          >
                                            Save changes
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* modal-red */}
                                </td>
                                <td>{value.Approval}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <ReactPaginate
                      previousLabel={"<< "}
                      nextLabel={" >>"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      // renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
