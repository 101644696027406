import React from "react";
import Layout from "../Layout/Content";
const Content = () => {
  return (
    <Layout>
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-home">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-head">
                    <div className="row">
                      <div className="col-2">
                        <i className="fab fa-stack-exchange" />
                      </div>
                      <div className="col-10">
                        <h5>Total Member</h5>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="info">
                      <h3>1200</h3>
                      {/* <p>$ 521.47</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-head">
                    <div className="row">
                      <div className="col-2">
                        <i className="far fa-sack-dollar" />
                      </div>
                      <div className="col-10">
                        <h5>Total Comission</h5>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="info">
                      <h3>$ 800</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-head">
                    <div className="row">
                      <div className="col-2">
                        <i className="far fa-copy" />
                      </div>
                      <div className="col-10">
                        <h5>Active Members</h5>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="info">
                      <h3>1000</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-head">
                    <div className="row">
                      <div className="col-2">
                        <i className="far fa-copy" />
                      </div>
                      <div className="col-10">
                        <h5>Inactive Members</h5>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="info">
                      <h3>200</h3>
                    </div>
                    {/* <a href="#" class="button">Withdrawl</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
