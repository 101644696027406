import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { toast } from "react-toastify";
import axios from "axios";
import { ethers } from "ethers";
import { USDTAbi, USDTAddress } from "../../Abi/Content";
import Loading from "../LoaderPageSection/Loading";
import ReactPaginate from "react-paginate";
import { async } from "q";

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");

  const [withdrawData, setWithdrawData] = useState([]);
  const [withdrawFiled, serWithdrawFiled] = useState({
    accountAddress: "",
    withdrawalAmount: "",
    userId: "",
    _id: "",
    commission: "",
    totalWithdrawalAmount: "",
  });
  const [rejectData, setRejectData] = useState({
    userId: "",
    id: "",
    withdrawalAmount: "",
  });
  const [admin, setAdmin] = useState("");
  const adminAddress = "0x259e3c59d6468d4d3cf84f69015e095d76d98ad2";
  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = withdrawData?.data;
  const pageCount = withdrawData?.pagination?.totalPages;
  const networkId = 137; //mainnet
  const handlePageClick = async (event) => {
    const resp = await axios.get(
      `/api/admin/getAll-withdrawalRequests?page=${event.selected + 1}`
    );
    const newOffset = (event.selected * itemsPerPage) % withdrawData.length;
    setWithdrawData(resp?.data);
    setItemOffset(newOffset);
  };

  /*Switch to mainnet*/
  const networkData = [
    {
      chainId: ethers.utils.hexlify(networkId),
      rpcUrls: ["https://polygon-rpc.com"],
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC", // 2-6 characters long
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
  ];

  const switchNetworks = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(networkId) }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: networkData,
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  };

  const handleWithdrawDetails = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/admin/getAll-withdrawalRequests`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setWithdrawData(resp?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleWithdrawApi = async (receipttxn) => {
    try {
      const withdrawData = {
        userId: withdrawFiled.userId,
        transactionHash: receipttxn.transactionHash,
        withdrawalAmount: withdrawFiled.withdrawalAmount,
        id: withdrawFiled._id,
        commission: withdrawFiled.commission,
        totalWithdrawalAmount: withdrawFiled.totalWithdrawalAmount,
        accountAddress: withdrawFiled.accountAddress,
      };
      const resp = await axios.post(
        `/api/admin/approve-withdrawalRequests`,
        withdrawData
      );
      if (resp.status === 200) {
        if (resp.data.status === true) {
          toast.success(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        }
        if (resp.data.status === false) {
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      if (network.chainId !== networkId) {
        await switchNetworks();
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      localStorage.setItem("address", address);
      setAdmin(address);
      if (address === adminAddress) {
        try {
          const USDTWithdraw = new ethers.Contract(
            USDTAddress,
            USDTAbi,
            signer
          );
          const txn = await USDTWithdraw.transfer(
            withdrawFiled.accountAddress,
            withdrawFiled.totalWithdrawalAmount * 10 ** 6
          );
          const receipttxn = await txn.wait();
          if (receipttxn.status !== 1) {
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            await handleWithdrawApi(receipttxn);
          }
        } catch (error) {
          console.log("error", error);
          const obj = JSON.stringify(error);
          const obj2 = JSON.parse(obj);
          const obj3 = obj2.reason;
          toast.error(obj3, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Please connect correct admin Wallet", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log("error", error);
      const obj = JSON.stringify(error);
      const obj2 = JSON.parse(obj);
      const obj3 = obj2.reason;
      toast.error(obj3, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
  };

  const handleWithdrawReject = async () => {
    setLoading(true);
    try {
      const data = {
        userId: rejectData?.userId,
        message: rejectMessage,
        ReasonOfRejection: reason,
        id: rejectData?.id,
        withdrawalAmount: rejectData?.withdrawalAmount,
      };
      const resp = await axios.post(
        `/api/admin/reject-withdrawalRequests`,
        data
      );
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
        window.location.reload(false);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
        window.location.reload(false);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
        window.location.reload(false);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleWithdrawDetails();
  }, []);

  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="db-content">
        <div className="container-fluid">
          <div className="db-section db-withdraw">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h2 className="db-heading">Withdraw Form</h2>
                  <hr className="my-4" />
                  <div className="table-responsive">
                    <table id="myTable" className="display table">
                      <thead>
                        <tr>
                          <th>UserId</th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>Amount</th>
                          <th>
                            Commission <small>(6%)</small>
                          </th>
                          <th>Total Amount</th>
                          <th>Withdraw</th>
                          <th>Reject</th>
                          <th>Status</th>
                          <th>Date Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems.map((value, key) => {
                            return (
                              <>
                                <tr>
                                  <td>{value?.userId}</td>
                                  <td>{value?.email}</td>
                                  <td>{value?.accountAddress}</td>
                                  <td>{value?.withdrawalAmount}</td>
                                  <td>{value?.commission}</td>
                                  <td>{value?.totalWithdrawalAmount}</td>
                                  <td>
                                    {/* Button trigger modal */}
                                    <button
                                      type="button"
                                      className="cus-btn"
                                      data-bs-toggle={
                                        value?.Approval == "approved" ||
                                        value?.Approval == "rejected"
                                          ? ""
                                          : "modal"
                                      }
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        if (
                                          value?.Approval == "approved" ||
                                          value?.Approval == "rejected"
                                        ) {
                                          toast.warning(
                                            `The withdrawal has already been ${value?.Approval}`
                                          );
                                        } else {
                                          serWithdrawFiled({
                                            accountAddress:
                                              value?.accountAddress,
                                            withdrawalAmount:
                                              value?.withdrawalAmount,
                                            userId: value?.userId,
                                            _id: value?._id,
                                            commission: value?.commission,
                                            totalWithdrawalAmount:
                                              value?.totalWithdrawalAmount,
                                            Approval: value?.Approval,
                                          });
                                        }
                                      }}
                                    >
                                      Withdraw
                                    </button>
                                    {/* Modal */}
                                    <div
                                      className="modal fade"
                                      id="exampleModal"
                                      tabIndex={-1}
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                          <button
                                            type="button"
                                            className="close-modal"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <i className="far fa-times" />
                                          </button>
                                          <div className="modal-body">
                                            <h2 className="db-heading ">
                                              Withdraw Information
                                            </h2>
                                            <hr className="my-4" />
                                            <div className="form">
                                              <div className="row mb-3 align-items-center">
                                                <div className="col-4">
                                                  <label htmlFor="name">
                                                    Admin
                                                  </label>
                                                </div>
                                                <div className="col-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Admin Address"
                                                    value={adminAddress}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row mb-3 align-items-center">
                                                <div className="col-4">
                                                  <label htmlFor="email">
                                                    Address
                                                  </label>
                                                </div>
                                                <div className="col-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Address"
                                                    value={
                                                      withdrawFiled.accountAddress
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="row mb-3 align-items-center">
                                                <div className="col-4">
                                                  <label htmlFor="password">
                                                    Amount
                                                  </label>
                                                </div>
                                                <div className="col-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Amount"
                                                    value={
                                                      withdrawFiled.totalWithdrawalAmount
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <button
                                                className="button mt-2 w-100"
                                                onClick={() => handleWithdraw()}
                                              >
                                                Withdraw
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {/* Button trigger modal */}
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-bs-toggle={
                                        value?.Approval === "approved" ||
                                        value?.Approval == "rejected"
                                          ? ""
                                          : "modal"
                                      }
                                      data-bs-target="#exampleModalinactive"
                                      onClick={() => {
                                        if (
                                          value?.Approval === "approved" ||
                                          value?.Approval == "rejected"
                                        ) {
                                          toast.warning(
                                            `The withdrawal has already been ${value?.Approval}`
                                          );
                                        } else {
                                          setRejectData({
                                            userId: value?.userId,
                                            id: value?._id,
                                            withdrawalAmount:
                                              value?.withdrawalAmount,
                                          });
                                        }
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </td>

                                  <td>{value?.Approval}</td>
                                  <td>{value?.withdrawalRequestDate}</td>
                                </tr>
                                {/* Modal  */}
                                <div
                                  className="modal fade"
                                  id="exampleModalinactive"
                                  tabIndex={-1}
                                  aria-labelledby="exampleModalLabelinactive"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog p-0 radius-0">
                                    <div className="modal-content rounded-0">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title text-light"
                                          id="exampleModalLabelinactive"
                                        >
                                          Reject Reason
                                        </h5>
                                        <button
                                          type="button"
                                          className="close-modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <i class="far fa-times"></i>{" "}
                                        </button>
                                      </div>
                                      <div className="modal-body p-0">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label text-light"
                                          >
                                            Reason
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Enter Reason"
                                            value={reason}
                                            onChange={(e) =>
                                              setReason(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <label
                                            htmlFor="exampleFormControlTextarea1"
                                            className="form-label text-light"
                                          >
                                            message
                                          </label>
                                          <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            defaultValue={""}
                                            value={rejectMessage}
                                            onChange={(e) =>
                                              setRejectMessage(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => handleWithdrawReject()}
                                        >
                                          Save changes
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* modal-red */}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate
                    previousLabel={"<< "}
                    nextLabel={" >>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-end "}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    // renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
