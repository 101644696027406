import React, { useState } from "react";
import Layout from "../Layout/Content";
import "./style.css";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const [coinName, setCoinName] = useState("");
  const [open, setOpen] = useState("");
  const [high, setHigh] = useState("");
  const [low, setLow] = useState("");
  const [close, setClose] = useState("");
  const [loading, setLoading] = useState("");
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        coinName,
        open,
        high,
        low,
        close,
      };
      const resp = await axios.post(`/api/admin//addCandalData`, data);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
        window.location.reload(false);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
        window.location.reload(false);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
        window.location.reload(false);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card candel-card">
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    Select Coin
                  </label>
                </div>
                <div className="col-8">
                  <select
                    className="form-select"
                    value={coinName}
                    onChange={(e) => setCoinName(e.target.value)}
                  >
                    <option value="" disabled>
                      ----Select Coin Mode----
                    </option>
                    <option selected value="HyperXAlpha">
                      HyperXAlpha
                    </option>
                    <option value="HyperCoin">HyperCoin</option>
                  </select>
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    Open
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Open"
                    value={open}
                    onChange={(e) => setOpen(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    High
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter High"
                    value={high}
                    onChange={(e) => setHigh(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    Low
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Low"
                    value={low}
                    onChange={(e) => setLow(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-4">
                  <label htmlFor="email" className="text-light">
                    close
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Close"
                    value={close}
                    onChange={(e) => setClose(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3 align-items-center">
                <div className="col-6">
                  <button className="cus-btn" onClick={() => handleSubmit()}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
