import React, { useState, useEffect } from "react";
import loginimg from "../../images/login.svg";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";

const Content = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = {
        email,
        password,
      };
      const resp = await axios.post(`/api/admin/admin-login`, data);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAuth({
            ...auth,
            user: resp.data.data,
            token: resp.data.token,
          });
          localStorage.setItem("auth", JSON.stringify(resp.data));
          navigate("/dashboard");
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/");
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <section className="login-page db-section bg-dark-alt">
        <div className="container-fluid px-lg-4">
          <div className="row align-items-center g-5">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="card">
                <img src={loginimg} alt="not" />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="login">
                <div className="logo">
                  <a href="./index.html">
                    <span>H</span>XA
                  </a>
                </div>
                <h4>Welcome to HXA</h4>
                <p className="mb-4">
                  Please sign-in to your account and start the adventure
                </p>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div>
                  <NavLink to={"/forgot-email"} className="text-primary">
                    Forgot Your Password?
                  </NavLink>
                </div>
                <button
                  className="button w-100 mb-3"
                  onClick={() => handleLogin()}
                >
                  Login
                </button>
                {/* <p className="text-center">
                  New on our platform?
                  <a href="./register.html" className="text-primary">
                    Create an account
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
