import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AuthProvider } from "./sections/context/auth";
const root = ReactDOM.createRoot(document.getElementById("root"));
// axios.defaults.baseURL = "https://devbackend.hyperxalpha.com";
// axios.defaults.baseURL = "http://192.168.1.5:4000";
axios.defaults.baseURL = "https://backend.hyperxalpha.com";
root.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </AuthProvider>
);
reportWebVitals();
