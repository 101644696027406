import React, { useEffect, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
export default function Content() {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [resetToken, setResetToken] = useState("");

  const handleChangePassword = async () => {
    try {
      if (password !== "" && confirmpassword !== "") {
        if (password === confirmpassword) {
          const dataResponse = await axios({
            method: "POST",
            url: "/api/admin/admin-ResetPassword",
            data: {
              id: id,
              newPassword: password,
              resetToken: resetToken,
            },
          });
          const outputData = dataResponse?.data;
          if (dataResponse.status === 200) {
            if (outputData.status === true) {
              navigate("/");
              window.location.reload(false);
              toast.success(outputData.message);
            } else {
              toast.error(outputData.message);
            }
          }
        } else {
          toast.error("Password and confirm password not same");
        }
      } else {
        toast.error("fill Password and ConfirmPasswor");
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  useEffect(() => {
    const dataSplit = location?.search.split("/");
    var userId = dataSplit[0].split("?")[1];
    const token = dataSplit[1];
    setId(userId);
    setResetToken(token);
  }, [location?.pathname]);
  return (
    <>
      <section className="logbg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="logbox">
                <h3>Reset Password</h3>

                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="mb-1">
                    {" "}
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control cus-formcontrol forgotcus"
                    id="exampleInputPassword1"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group forgot-formcontrol mb-3">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="mb-1 forgontlable"
                  >
                    Confirmation Password
                  </label>
                  <input
                    type="password"
                    className="form-control cus-formcontrol forgotcus"
                    placeholder="Confirmation Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="btn form-btn w-100 forgotbtn"
                  onClick={handleChangePassword}
                >
                  Next Step
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
